@import "https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700";
html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  letter-spacing: 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga" on;
  color: #ddd;
  background-color: #121212;
  margin: 0;
  font-family: Quicksand, sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  overflow-x: hidden;
}

a {
  color: #002d72;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.wrapper {
  width: 100%;
  min-width: 320px;
  max-width: 1024px;
  margin: 0 auto;
}

.wrapper > * {
  min-width: 100%;
  margin: 0;
  padding: 0 1.7rem;
  position: relative;
}

.wrapper > header {
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: nowrap;
  height: 4.4rem;
  padding-top: .8rem;
  padding-bottom: .8rem;
  display: flex;
}

.wrapper > header:before {
  content: "";
  z-index: -1;
  background-color: #002d72;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
}

.wrapper > header a {
  text-decoration: none;
}

.wrapper > header .header-logo {
  background-image: url("irf-neg.ad6b47ed.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 0 0 300px;
  height: auto;
  display: block;
  position: relative;
}

.wrapper > header .header-title {
  text-align: right;
  white-space: nowrap;
  color: #fff;
  letter-spacing: .1px;
  text-transform: uppercase;
  word-spacing: .2em;
  flex: auto;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.8rem;
  position: relative;
}

.wrapper > header .header-title a {
  color: #fff;
  text-decoration: none;
}

.wrapper > nav {
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  height: 3.4rem;
  display: flex;
}

.wrapper > nav:before {
  content: "";
  z-index: -1;
  background-color: #0000;
  border-bottom: 1px solid #e5e5e5;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
}

.wrapper > nav * {
  margin: 0;
  padding: 0;
}

.wrapper > nav a {
  text-decoration: none;
}

.wrapper > nav .nav-toggle {
  display: none;
}

.wrapper > nav .nav-menu, .wrapper > nav .nav-lang {
  z-index: 1;
  display: flex;
}

.wrapper > nav .nav-menu li, .wrapper > nav .nav-lang li {
  display: block;
  position: relative;
}

.wrapper > nav .nav-menu li.nav-current > a, .wrapper > nav .nav-lang li.nav-current > a {
  color: #002d72;
  font-weight: 700;
}

.wrapper > nav .nav-menu li.nav-current > a:before, .wrapper > nav .nav-lang li.nav-current > a:before {
  height: 2px;
}

.wrapper > nav .nav-menu a, .wrapper > nav .nav-lang a {
  letter-spacing: .03em;
  color: #002d72b3;
  white-space: nowrap;
  text-transform: uppercase;
  height: 100%;
  font-size: .8rem;
  font-weight: 600;
  line-height: 3.4rem;
  display: block;
}

.wrapper > nav .nav-menu a:before, .wrapper > nav .nav-lang a:before {
  content: " ";
  padding: inherit;
  background-color: #002d72;
  background-clip: content-box;
  width: 100%;
  height: 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.wrapper > nav .nav-menu a:hover, .wrapper > nav .nav-lang a:hover {
  color: #002d72;
}

.wrapper > nav .nav-menu a:hover:before, .wrapper > nav .nav-lang a:hover:before {
  height: 2px;
  transition: all .1s linear;
}

.wrapper > nav .nav-menu {
  flex: auto;
  margin-left: -1rem;
}

.wrapper > nav .nav-menu a {
  padding: 0 1rem;
}

.wrapper > nav .nav-lang {
  flex: none;
  margin-right: -.5rem;
}

.wrapper > nav .nav-lang a {
  padding: 0 .5rem;
}

.wrapper > nav .nav-submenu {
  z-index: 2;
  white-space: nowrap;
  background-color: #456698;
  flex-direction: column;
  min-width: 100%;
  padding: .5rem 0;
  display: none;
  position: absolute;
  top: 3.4rem;
  left: 0;
}

.wrapper > nav .nav-submenu a {
  text-transform: none;
  color: #fff;
  font-weight: 400;
  line-height: 2.2rem;
}

.wrapper > nav .nav-submenu a:before {
  bottom: .6em;
}

.wrapper > nav .nav-submenu a:hover {
  color: #fff;
}

.wrapper > nav .nav-submenu a:hover:before {
  background-color: #fff;
  height: 1px;
}

.wrapper > nav .nav-submenu ~ a:hover, .wrapper > nav .nav-submenu:hover ~ a {
  color: #fff;
  cursor: default;
  background-color: #456698;
  text-decoration: none;
}

.wrapper > nav .nav-submenu ~ a:hover.link, .wrapper > nav .nav-submenu:hover ~ a.link {
  cursor: pointer;
}

.wrapper > nav .nav-submenu ~ a:hover:before, .wrapper > nav .nav-submenu:hover ~ a:before {
  background-color: #fff;
}

.wrapper > nav .nav-menu li:hover .nav-submenu {
  display: flex;
}

.wrapper > article {
  border-bottom: 1px solid #e5e5e5;
  min-height: 100vh;
  padding-top: 3.4rem;
  padding-bottom: 40px;
  display: block;
}

.wrapper > article:empty:after {
  content: "Page content";
  color: #e5e5e5;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper > footer {
  height: 5rem;
}

.wrapper > footer:empty:after {
  content: "INSTITUTET FÖR RYMDFYSIK \a Swedish Institute of Space Physics";
  white-space: pre-wrap;
  color: #002d72;
  font-size: .8rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.wrapper > footer span {
  white-space: pre-wrap;
  color: #002d72;
  font-size: .9rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
}

.wrapper > footer span a {
  text-decoration: underline;
}

@media (width >= 768px) {
  nav.sticky {
    min-width: inherit;
    max-width: inherit;
    width: inherit;
    position: fixed;
    top: 0;
  }

  nav.sticky:before {
    background-color: #002d72;
    border: 0;
    transition: background-color .3s linear;
  }

  nav.sticky ~ article {
    margin-top: 3.4rem;
  }

  nav.sticky .nav-menu a, nav.sticky .nav-lang a {
    color: #ffffffd9;
    transition: color .3s linear;
  }

  nav.sticky .nav-menu a:before, nav.sticky .nav-lang a:before {
    background-color: #fff;
    margin-bottom: 0;
  }

  nav.sticky .nav-menu a:hover, nav.sticky .nav-lang a:hover {
    color: #fff;
  }

  nav.sticky .nav-menu a:hover:before, nav.sticky .nav-lang a:hover:before {
    height: 4px;
  }

  nav.sticky .nav-menu li.nav-current a, nav.sticky .nav-lang li.nav-current a {
    color: #fff;
  }

  nav.sticky .nav-menu li.nav-current a:before, nav.sticky .nav-lang li.nav-current a:before {
    height: 4px;
  }

  nav.sticky .nav-submenu a:before {
    border-bottom: .6rem;
  }

  nav.sticky .nav-submenu a:hover:before {
    height: 1px;
  }

  nav.sticky .nav-submenu ~ a:hover, nav.sticky .nav-submenu:hover ~ a {
    border-color: #456698;
  }
}

@media (width <= 767px) {
  .wrapper > header {
    z-index: 1;
    min-width: inherit;
    width: 100%;
    height: 3.4rem;
    position: fixed;
    top: 0;
  }

  .wrapper > header:before {
    margin-left: 0;
    margin-right: 0;
    left: 0;
    right: 0;
  }

  .wrapper > header .header-logo {
    display: none;
  }

  .wrapper > header .header-title {
    text-align: center;
    text-overflow: clip;
    text-transform: uppercase;
    flex-basis: auto;
    width: 100%;
    margin: 0 1.7rem;
    font-size: .9rem;
    font-weight: 500;
    line-height: 1.8rem;
    overflow: hidden;
  }

  .wrapper > nav {
    z-index: 2;
    width: 100%;
    min-width: 320px;
    height: auto;
    position: fixed;
    top: 3.4rem;
  }

  .wrapper > nav:before {
    background-color: #002d72;
    margin-left: 0;
    margin-right: 0;
    left: 0;
    right: 0;
  }

  .wrapper > nav label.nav-toggle {
    color: #fff;
    margin-top: -3.4rem;
    font-size: 1.2rem;
    line-height: 3.4rem;
    display: block;
    position: relative;
    top: 0;
    left: 0;
  }

  .wrapper > nav label.nav-toggle:before {
    content: "☰";
    cursor: pointer;
  }

  .wrapper > nav input.nav-toggle:checked + label:before {
    content: "⨯";
  }

  .wrapper > nav input.nav-toggle:checked ~ .nav-menu {
    flex-flow: column;
    display: flex;
  }

  .wrapper > nav .nav-lang li.nav-current a, .wrapper > nav .nav-menu li.nav-current a {
    color: #fff;
    font-weight: 500;
  }

  .wrapper > nav .nav-lang {
    display: none;
  }

  .wrapper > nav .nav-menu {
    border-top: 1px solid #456698;
    width: 100vw;
    height: 95vh;
    display: none;
    overflow-y: scroll;
  }

  .wrapper > nav .nav-menu > li {
    border-bottom: 1px solid #456698;
    flex-direction: column-reverse;
    flex: none;
    width: 100%;
    height: auto;
    display: flex;
  }

  .wrapper > nav .nav-menu a {
    color: #fff;
    background-color: #0000;
    padding-left: 0;
    padding-right: 0;
    font-size: .9rem;
  }

  .wrapper > nav .nav-menu a:hover {
    color: #fff;
  }

  .wrapper > nav .nav-menu a:before {
    display: none;
  }

  .wrapper > nav .nav-submenu {
    background-color: #0000;
    padding-top: 0;
    padding-left: 1rem;
    position: relative;
    top: 0;
  }

  .wrapper > nav .nav-submenu ~ a:after {
    content: "﹥";
    vertical-align: middle;
    padding-left: .5rem;
  }

  .wrapper > nav .nav-submenu ~ a:hover, .wrapper > nav .nav-submenu:hover ~ a {
    background-color: #0000;
  }

  .wrapper > nav .nav-submenu ~ a:hover:after, .wrapper > nav .nav-submenu:hover ~ a:after {
    content: "﹥";
  }

  .wrapper > main {
    margin-top: 2.8rem;
    position: relative;
  }

  .wrapper > footer {
    display: none;
  }
}

figure {
  margin: 1em 0;
}

img, object, video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.error {
  color: #af272f;
}

.overlay {
  position: relative;
}

.kindex .overlay figcaption.error {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 23%;
  padding-top: 1vh;
  font-size: max(2vw, .8em);
  line-height: 1.2em;
  display: block;
  position: absolute;
  top: 27%;
  left: 0;
}

.kindex .overlay img {
  z-index: 0;
}

@media (prefers-color-scheme: dark) {
  .kindex figcaption.error {
    filter: invert(.93) saturate(.7) hue-rotate(180deg);
  }
}

.home object[type="image/svg+xml"] {
  pointer-events: none;
}

.home .tiles {
  flex-wrap: wrap;
  justify-content: center;
  margin: 4rem 0 0;
  padding: 0;
  display: flex;
}

.home .tiles > * {
  text-align: center;
  flex: 0 0 300px;
  display: block;
}

.home .tiles > * a {
  text-decoration: none;
}

.home .tiles > * a:hover figcaption, .home .tiles > * a:focus figcaption {
  text-decoration: underline;
}

.home .tiles > * .tile-media-wrapper {
  margin: 1rem;
  position: relative;
}

.news {
  flex-direction: column;
  display: flex;
}

.news .news-list > a {
  display: none;
}

.news .news-item {
  flex-direction: row;
  display: flex;
}

.news .news-item .news-item-date {
  white-space: nowrap;
  width: auto;
  min-width: 120px;
}

.news .news-item .news-item-image {
  min-width: 200px;
  max-width: 200px;
}

.news .news-item .news-item-image img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.news .news-item .news-item-content {
  border-left: 1px solid #e5e5e5;
  width: 100%;
  padding: 0 1rem;
}

.news .news-item .news-item-content h4 {
  margin-top: 0;
}

.news .news-item:not(:last-child) {
  margin-bottom: 2rem;
}

@media only screen and (width <= 768px) {
  .home .tiles > * {
    flex-basis: 100%;
  }

  .news .news-item {
    flex-direction: column;
  }

  .news .news-item .news-item-content {
    border: 0;
    padding: 0;
  }

  .news .news-item:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem 0;
  }
}

@media (prefers-color-scheme: dark) {
  .wrapper > header {
    opacity: .8;
  }

  .wrapper > article {
    border-bottom-color: #222;
  }

  .wrapper > nav:before {
    background-color: #121212;
    border-color: #222;
  }

  .wrapper > nav .nav-menu a {
    color: #5b7fb5;
  }

  .wrapper > nav .nav-menu a:hover, .wrapper > nav .nav-menu li.nav-current > a {
    color: #fff;
  }

  .wrapper > footer span {
    color: #ddd;
  }

  a {
    color: #5b7fb5;
  }

  object[data*="irf-"], img[src*="irf-"] {
    filter: invert(.93) saturate(.7) hue-rotate(180deg);
  }

  img[src*="gong-farside"] {
    filter: invert() brightness(.9) hue-rotate(180deg);
  }

  img[src*="sdo-harp"] {
    filter: invert(.07);
  }
}

@media (prefers-color-scheme: light) {
  body {
    color: #000;
    background: #fff;
  }
}
/*# sourceMappingURL=index.8f534aae.css.map */
