figure {
  margin: 1em 0;
}

img,
object,
video {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.error {
  color: $irfred;
}

.overlay {
  /* to overlay error message relative to figure */
  position: relative;
}

.kindex .overlay figcaption.error {
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  /* fit location and size of kindex-plot in all screen sizes */
  top: 27%;
  height: 23%;
  padding-top: 1vh;
  font-size: max(2vw, .8em);
  line-height: 1.2em;
  background-color: white;
  z-index: 10;
}

.kindex .overlay img {
  z-index: 0;
}

@media (prefers-color-scheme: dark) {
  .kindex figcaption.error {
    filter: invert(0.93) saturate(0.7) hue-rotate(180deg)
  }
}

.home {
  object[type="image/svg+xml"] {
    /* make the parent link handle the click instead */
    pointer-events: none;
  }

  .tiles {
    margin: 4rem 0 0 0;
    padding: 0;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > * {
      display: block;
      text-align: center;
      flex: 0 0 300px;

      a {
        text-decoration: none;

        &:hover,
        &:focus {
          figcaption {
            text-decoration: underline;
          }
        }
      }

      .tile-media-wrapper {
        margin: 1rem;
        position: relative;
      }
    }
  }
}

.news {
  display: flex;
  flex-direction: column;

  .news-list {
    > a {
      display: none;
    }
  }

  .news-item {
    display: flex;
    flex-direction: row;
    .news-item-date {
      width: auto;
      min-width: 120px;

      white-space: nowrap;
    }

    .news-item-image {
      min-width: 200px;
      max-width: 200px;

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
    .news-item-content {
      width: 100%;

      border-left: 1px solid #e5e5e5;
      padding: 0 1rem;
      h4 {
        margin-top: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    .tiles {
      > * {
        flex-basis: 100%;
      }
    }
  }

  .news {
    .news-item {
      flex-direction: column;

      .news-item-content {
        border: 0;
        padding: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
        padding: 1rem 0;
      }
    }
  }
}
