@media (prefers-color-scheme: dark) {
  .wrapper > header {
    opacity: 0.8;
  }

  .wrapper > article {
    border-bottom-color: #222;
  }

  .wrapper > nav::before {
    background-color: #121212;
  }

  .wrapper > nav {
    &::before {
      border-color: #222;
    }

    .nav-menu a {
      color: $lighterblue;
    }

    .nav-menu a:hover {
      color: white;
    }

    .nav-menu li.nav-current > a {
      color: white;
    }
  }

  .wrapper > footer span {
    color: #ddd;
  }

  a {
    color: $lighterblue;
  }

  object[data*="irf-"],
  img[src*="irf-"] {
    filter: invert(0.93) saturate(0.7) hue-rotate(180deg);
  }

  img[src*="gong-farside"] {
    filter: invert(1) brightness(0.9) hue-rotate(180deg);
  }

  img[src*="sdo-harp"] {
    filter: invert(0.07);
  }
}
