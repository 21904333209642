@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700);

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga" on;
  margin: 0;
  line-height: 1.5rem;
  // to avoid firefox horizontal scrollbar
  // from auto-margin powered full width navigation bar
  overflow-x: hidden;
  // darkmode default to avoid flashing
  background-color: #121212;
  color: #ddd;
}

a {
  color: $irfblue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

$nav-height: 3.4rem;
$header-height: 5rem;
$footer-height: 5rem;
$title-font-size: $header-height * 1.3;

.wrapper {
  margin: 0 auto;
  max-width: 1024px;
  min-width: 320px;
  width: 100%;

  > * {
    min-width: 100%;
    position: relative;
    padding: 0 1.7rem;
    margin: 0;
  }

  > header {
    user-select: none;
    display: flex;
    flex-wrap: nowrap;
    height: 4.4rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      height: 100%;
      width: 100vw;
      background-color: $irfblue;
      z-index: -1;
    }

    a {
      text-decoration: none;
    }

    .header-logo {
      position: relative;
      display: block;
      flex: 0 0 300px;
      height: auto;

      background-image: url(../img/irf-neg.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
    }

    .header-title {
      position: relative;
      flex: 1 1 auto;
      line-height: 2.8rem;
      font-size: 1.3rem;
      font-weight: 400;
      text-align: right;
      white-space: nowrap;
      color: $white;

      letter-spacing: 0.1px;
      text-transform: uppercase;
      word-spacing: 0.2em;

      a {
        color: $white;
        text-decoration: none;
      }
    }
  }

  > nav {
    user-select: none;
    height: $nav-height;
    display: flex;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      height: 100%;
      width: 100vw;
      border-bottom: 1px solid $lightgrey;
      background-color: transparent;
      z-index: -1;
    }

    * {
      margin: 0;
      padding: 0;
    }

    a {
      text-decoration: none;
    }

    .nav-toggle {
      display: none;
    }

    .nav-menu,
    .nav-lang {
      z-index: 1;
      display: flex;
      li {
        display: block;
        position: relative;

        &.nav-current {
          > a {
            color: rgba($irfblue, 1);
            font-weight: 700;
            &::before {
              height: 2px;
            }
          }
        }
      }

      a {
        display: block;
        height: 100%;
        letter-spacing: 0.03em;
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 3.4rem;
        color: rgba($irfblue, 0.7);
        white-space: nowrap;
        text-transform: uppercase;

        &::before {
          display: block;
          content: " ";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 0;
          width: 100%;
          background-clip: content-box;
          background-color: $irfblue;
          padding: inherit;
        }
        &:hover {
          color: rgba($irfblue, 1);
          &::before {
            height: 2px;
            transition: 0.1s linear;
          }
        }
      }
    }

    .nav-menu {
      flex: 1 1 auto;
      margin-left: -1rem;

      a {
        padding: 0 1rem;
      }
    }

    .nav-lang {
      flex: 0 0 auto;
      margin-right: -0.5rem;

      a {
        padding: 0 0.5rem;
      }
    }

    .nav-submenu {
      z-index: 2;
      position: absolute;
      top: 3.4rem;
      left: 0;
      display: none;
      flex-direction: column;
      white-space: nowrap;
      min-width: 100%;
      background-color: $lightblue;
      padding: 0.5rem 0;

      a {
        font-weight: 400;
        text-transform: none;
        line-height: 2.2rem;
        color: $white;
        &::before {
          bottom: 0.6em;
        }
        &:hover {
          color: $white;
          &::before {
            height: 1px;
            background-color: $white;
          }
        }
      }

      ~ a:hover,
      &:hover ~ a {
        background-color: $lightblue;
        color: $white;
        cursor: default;
        text-decoration: none;

        &.link {
          cursor: pointer;
        }

        &::before {
          background-color: $white;
        }
      }
    }

    .nav-menu li:hover .nav-submenu {
      display: flex;
    }
  }

  > article {
    display: block;
    min-height: 100vh;
    padding-top: $nav-height;
    padding-bottom: 40px;
    border-bottom: 1px solid $lightgrey;
    &:empty::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "Page content";
      color: $lightgrey;
    }
  }

  > footer {
    height: $footer-height;
    &:empty::after {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      content: "INSTITUTET FÖR RYMDFYSIK \A Swedish Institute of Space Physics";
      white-space: pre-wrap;
      color: $irfblue;
      font-size: 0.8rem;
    }

    span {
      white-space: pre-wrap;
      font-size: 0.9rem;
      color: $irfblue;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      a {
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: 768px) {
  nav.sticky {
    position: fixed;
    min-width: inherit;
    max-width: inherit;
    width: inherit;

    top: 0;

    &::before {
      border: 0;
      background-color: $irfblue;
      transition: background-color 0.3s linear;
    }

    & ~ article {
      margin-top: 3.4rem;
    }

    .nav-menu,
    .nav-lang {
      a {
        transition: color 0.3s linear;
        color: rgba($white, 0.85);

        &::before {
          margin-bottom: 0;
          background-color: $white;
        }
        &:hover {
          color: rgba($white, 1);

          &::before {
            height: 4px;
          }
        }
      }

      li.nav-current {
        a {
          color: rgba($white, 1);
          &::before {
            height: 4px;
          }
        }
      }
    }
    .nav-submenu {
      a {
        &::before {
          border-bottom: 0.6rem;
        }
        &:hover {
          &::before {
            height: 1px;
          }
        }
      }

      ~ a:hover,
      &:hover ~ a {
        border-color: $lightblue;
      }
    }
  }
}

@media (max-width: 767px) {
  $header-height: $nav-height; //3.6rem;
  $nav-height: 2.8rem;
  $title-font-size: $header-height * 0.3;

  .wrapper {
    > header {
      position: fixed;
      z-index: 1;
      top: 0;
      width: 100%;

      min-width: inherit;
      height: 3.4rem;
      &::before {
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
      }

      .header-logo {
        display: none;
      }

      .header-title {
        font-weight: 500;
        text-align: center;
        flex-basis: auto;
        overflow: hidden;
        text-overflow: clip;
        width: 100%;
        font-size: 0.9rem;
        line-height: 1.8rem;
        text-transform: uppercase;
        margin: 0 1.7rem;
      }
    }

    > nav {
      min-width: 320px;
      position: fixed;
      z-index: 2;
      top: 3.4rem;
      width: 100%;
      height: auto;

      &::before {
        background-color: $irfblue;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
      }

      label.nav-toggle {
        display: block;
        position: relative;
        top: 0;
        left: 0;

        margin-top: -3.4rem;
        line-height: 3.4rem;
        font-size: 1.2rem;
        color: $white;

        &::before {
          content: "☰";
          cursor: pointer;
        }
      }

      input.nav-toggle {
        &:checked + label::before {
          content: "⨯";
        }
        &:checked ~ .nav-menu {
          display: flex;
          flex-flow: column;
        }
      }

      .nav-lang,
      .nav-menu {
        li.nav-current {
          a {
            color: $white;
            font-weight: 500;
          }
        }
      }

      .nav-lang {
        display: none;
      }

      .nav-menu {
        overflow-y: scroll;
        border-top: 1px solid $lightblue;
        display: none;
        width: 100vw;
        height: calc(95vh);

        > li {
          border-bottom: 1px solid $lightblue;
          display: flex;
          flex: 0 0 auto;
          flex-direction: column-reverse;
          height: auto;
          width: 100%;
        }

        a {
          color: $white;
          font-size: 0.9rem;
          padding-left: 0;
          padding-right: 0;

          background-color: transparent;
          &:hover {
            color: $white;
          }
          &::before {
            display: none;
          }
        }
      }

      .nav-submenu {
        position: relative;
        top: 0;
        background-color: transparent;
        padding-top: 0;
        padding-left: 1rem;

        ~ a {
          &::after {
            content: "﹥";
            vertical-align: middle;
            padding-left: 0.5rem;
          }
        }

        ~ a:hover,
        &:hover ~ a {
          background-color: transparent;

          &::after {
            content: "﹥";
          }
        }
      }
    }

    > main {
      position: relative;
      margin-top: 2.8rem;
    }
    > footer {
      display: none;
    }
  }
}
